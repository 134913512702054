.tab-container {
    margin-bottom: 15px;

    .tab {
        padding-bottom: 3px;
        color: var(--typography-primary);
        margin-right: 20px;
        border-bottom: 3px solid transparent;

        &.active {
            border-color: var(--background-button-primary);
        }
    }
}