.page-title, .page-description {
    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }
}

.page-title {
    margin-bottom: 15px;
    font-size: 18px;
}

.page-subtitle {
    margin-bottom: 10px;
    font-size: 18px;
}

.page-description {
    margin-bottom: 20px;
    font-size: 14px;
}

// message strip
.message-strip {
    padding: 15px;
    text-align: center;
    border-radius: $border-radius;

    * {
        margin-bottom: 0;
    }
}

@include screenSizeMobileFirst(smDesktop) {
    .page-title {
        font-size: 22px;
    }
}