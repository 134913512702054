@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

// colours
$overlay-grey: rgba(0, 0, 0, 0.3);

:root {
    --chart-slice: #3743bb;
    --chart-divider: #f2f4f1;
    --chart-section-divider: #222;
    --chart-circle-target: #cac373;
    --chart-typography-behaviour: #2e4f5e;
}

[data-theme="dark"] {
    --chart-typography-behaviour: white;
    --chart-section-divider: white;
}

// misc
$border-radius: 3px;

@mixin fullWidth() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// media queries
@mixin screenSizeDesktopFirst($size) {
    @if $size == lgDesktop {
        // large desktop
        @media only screen and (max-width: 1599px) {
            @content;
        }
    } @else if $size == mdDesktop {
        // medium desktop
        @media only screen and (max-width: 1279px) {
            @content;
        }
    } @else if $size == smDesktop {
        // small desktop
        @media only screen and (max-width: 1099px) {
            @content;
        }
    } @else if $size == lsTablet {
        // landscape tablet
        @media only screen and (max-width: 1023px) {
            @content;
        }
    } @else if $size == ptTablet {
        // portrait tablet
        @media only screen and (max-width: 800px) {
            @content;
        }
    } @else if $size == lgMobile {
        // large mobile
        @media only screen and (max-width: 767px) {
            @content;
        }
    } @else if $size == mdMobile {
        // medium mobile
        @media only screen and (max-width: 479px) {
            @content;
        }
    } @else if $size == smMobile {
        // small mobile
        @media only screen and (max-width: 413px) {
            @content;
        }
    } @else {
        // custom size
        @media only screen and (max-width: $size) {
            @content;
        }
    }
}

@mixin screenSizeMobileFirst($size) {
    @if $size == lgDesktop {
        // large desktop
        @media only screen and (min-width: 1280px) {
            @content;
        }
    } @else if $size == mdDesktop {
        // medium desktop
        @media only screen and (min-width: 1100px) {
            @content;
        }
    } @else if $size == smDesktop {
        // small desktop
        @media only screen and (min-width: 1024px) {
            @content;
        }
    } @else if $size == lsTablet {
        // landscape tablet
        @media only screen and (min-width: 801px) {
            @content;
        }
    } @else if $size == ptTablet {
        // portrait tablet
        @media only screen and (min-width: 768px) {
            @content;
        }
    } @else if $size == lgMobile {
        // large mobile
        @media only screen and (min-width: 480px) {
            @content;
        }
    } @else if $size == mdMobile {
        // medium mobile
        @media only screen and (min-width: 414px) {
            @content;
        }
    } @else if $size == smMobile {
        // small mobile
        @media only screen and (min-width: 360px) {
            @content;
        }
    } @else {
        // custom size
        @media only screen and (min-width: $size) {
            @content;
        }
    }
}