@import 'reset.scss';
@import 'variables.scss';
@import 'structure.scss';

body {
    line-height: 1.5;
}

.custom-scroll {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin: 10px 0px 10px 0px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        opacity: 0.1;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #dddddd;
    }
}

.clickable {
    cursor: pointer;
}

// components
@import 'components/button.scss';
@import 'components/chart.scss';
@import 'components/form.scss';
@import 'components/modal.scss';
@import 'components/table.scss';
@import 'components/tabs.scss';
@import 'components/typography.scss';

// pages
@import 'pages/error.scss';