:root {
    --background-body: white;
    --background-container: #f4f4f4;
    --shadow-container: 0px 1px 1px 1px rgb(0 0 0 / 15%);
    --shadow:  rgb(0 0 0 / 15%);
    --background-nav: #F8F9FA;
    --shadow-nav: inset -1px 0 0 rgb(0 0 0 / 10%);
    --background-header: #353535;
    --background-button-primary: #007bff;
    --typography-button-primary: white;
    --background-button-secondary: #dddddd;
    --typography-button-secondary: #222222;
    --background-button-positive: #46c531;
    --background-button-negative: #dc3545;
    --typography-primary: #222222;
    --background-form-input: white;
    --background-form-input-disabled: #f7f7f7;
    --background-form-multi-select-option: #eee;
    --stroke-form-input: #cccccc;
    --placeholder-form-input: #cdcdcd;
    --highlight-form: #6a6a6a;
    --highlight-form-error: #ea7b73;
    --active-form-selection: #007bff;
    --peer-drift: #f29c4b;
    --leader-drift: #4b9cf4;
    --background-form-error: rgba(234, 123, 115, 0.18);
    --stroke-table-row: #cccccc;
    --typography-link: #007bff;
    --assessment-chart-background: white;

    // from brand guidelines
    --decathlon-secondary: #FF600A;
    --people-primary: #004876;
    --know-dark: #018768;
    --know-light: #02D196;
    --grow-dark: #407AAD;
    --grow-light: #5EBAFF;
    --play-dark: #D14702;
    --play-light: #FF9855;
    --be-dark: #9C6D00;
    --be-light: #E3AA00;

    // colour keys

    --overall: rgb(142, 106, 179);
}

[data-theme="dark"] {
    --background-body: #181818;
    --background-container: #2d2d2d;
    --shadow-container: 0px 1px 1px 1px rgb(255 255 255 / 15%);
    --shadow:  rgb(255 255 255 / 15%);
    --background-nav: #222222;
    --shadow-nav: inset -1px 0 0 rgb(255 255 255 / 10%);
    --background-button-primary: #1bb8b0;
    --background-button-secondary: #b8b8b8;
    --typography-primary: white;
    --background-form-input: #181818;
    --background-form-input-disabled: #2c2c2c;
    --background-form-multi-select-option: #4f4f4f;
    --stroke-form-input: #5c5c5c;
    --placeholder-form-input: #a4a4a4;
    --highlight-form: #c3c3c3;
    --active-form-selection: #1bb8b0;
    --stroke-table-row: #5c5c5c;
    --people-primary: #eee;
    --assessment-chart-background: #181818;
}

@mixin topicColorLight() {
    &.know {
        color: var(--know-light);
    }
  
    &.grow {
        color: var(--grow-light);
    }
  
    &.play {
        color: var(--play-light);
    }
  
    &.be {
        color: var(--be-light);
    }
  }
  
  @mixin topicColorDark() {
    &.know {
        color: var(--know-dark);
    }
  
    &.grow {
        color: var(--grow-dark);
    }
  
    &.play {
        color: var(--play-dark);
    }
  
    &.be {
        color: var(--be-dark);
    }
  }
  
  @mixin topicBackgroundColorLight() {
    &.know {
        background-color: var(--know-light);
    }
  
    &.grow {
        background-color: var(--grow-light);
    }
  
    &.play {
        background-color: var(--play-light);
    }
  
    &.be {
        background-color: var(--be-light);
    }
  }
  
  @mixin topicBackgroundColorDark() {
    &.know {
        background-color: var(--know-dark);
    }
  
    &.grow {
        background-color: var(--grow-dark);
    }
  
    &.play {
        background-color: var(--play-dark);
    }
  
    &.be {
        background-color: var(--be-dark);
    }
  }

// fonts
@mixin mainFont() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin mainFontLight() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

@mixin mainFontSemiBold() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}