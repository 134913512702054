.assessmentResult {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    &.percentageResult {
        color: var(--typography-button-secondary);
        background-color: rgb(214, 209, 203);
        border-radius: 50%;
    }
}

.assessment-arrow {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    &.up {
        border-bottom: 10px solid green;
    }
    &.down {
        border-top: 10px solid #f00;
    }
}

.assessment-no-value-sign {
    position: relative;
    top: 10px;
    left: 5px;
    margin: 0 10px 0 10px;
} 

.collapsible-content {
    display: none;
    overflow: hidden;
}

.collapsible {
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    margin-left: 10px;
    color: var(--typography-primary);

    i {
        transform: rotate(-90deg);
        &.down {
            transform: rotate(0deg);
        }
    }
}

.switch-chart-btn {
    padding: 10px 25px;
    background-color: transparent;
    border: 1px solid var(--shadow);
    color: var(--typography-primary);
    margin-left: 16px;
}