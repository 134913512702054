// header
.header {
    display: flex;
    justify-content: space-between;
    flex: 0 0 75px;
    width: 100%;
    background-color: var(--background-header);

    .logo {
        display: flex;
        width: 200px;
        align-items: center;
        justify-content: center;
        color: white;
        
        img {
            height: 26px;
        }
    }

    .content {
        flex: 1;
    }

    .dark-mode-switch {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

    .profile-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

        p {
            color: white;
            margin-right: 20px;
            margin-left: 20px;
        }
   
        .profile-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: white;
            margin-right: 20px;
            i {
                transform: scale(1.8);
                color: black;
            }
        }
    }
}