// row
.button-row {
    display: flex;
    justify-content: center;
    
    .button {
        margin: 0 5px;
    }

    &.left {
        justify-content: flex-start;

        .button:first-child {
            margin-left: 0;
        }
    }

    &.right {
        justify-content: flex-end;

        .button:last-child {
            margin-right: 0;
        }
    }
}

// button
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    font-size: 14px;
    border-radius: $border-radius;
    outline: none;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;
    user-select: none;

    .icon {
        margin-right: 10px;
        margin-top: 2px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5!important;
        cursor: not-allowed;
    }
}