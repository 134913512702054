.assessment-info {
    .score {
        @include topicBackgroundColorLight;

        .score-value, .score-title, .score-subvalue {
            @include mainFontSemiBold;
        }
    }
}

.assessment-chart {
    // circles
    .circle {
        // centre circle
        &.centre {
            background-color: var(--assessment-chart-background);
            border-color: var(--assessment-chart-background);
        }

        // sections
        &.sections {
            .point {
                border: 4px solid var(--people-primary);

                @include topicBackgroundColorLight;
            }

            .title-wrapper {
                .title {
                    @include mainFontSemiBold;
                }

                .sub-title {
                    @include topicColorDark;
                }
            }

            .blocker {
                background-color: var(--assessment-chart-background);
            }
        }
    }
}

// svg headers
.topic-main-title {
    fill: var(--people-primary);
}