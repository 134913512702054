.assessment-chart-outer {
    width: 1200px;
    height: 800px;
    font-size: 10px;

    &.small {
        width: 1000px;
        height: 700px;
        font-size: 8.5px;

        .assessment-chart {
            width: 700px;
            height: 700px;
        }

        .assessment-info {
            width: 150px;
        }
    }
}

.assessment-info {
    width: 200px;
    height: 100%;
    padding: 60px 0;

    .score {
        padding: 10px 0;
        width: 150px;
        user-select: none;

        &.left {
            border-right: 5px solid transparent;
            padding-right: 10px;
            text-align: right;
        }

        &.right {
            border-left: 5px solid transparent;
            padding-left: 10px;
        }
    }

    .score-value {
        font-size: 32px;
        line-height: 1.2;
    }

    .score-title {
        font-size: 12px;
    }

    .score-subvalue {
        margin-top: 10px;
        font-size: 24px;
        line-height: 1.2;
    }

    .score-subtitle {
        font-size: 10px;
    }
}

.assessment-chart {
    width: 800px;
    height: 800px;
    border-radius: 50%;

    p {
        user-select: none;
    }

    svg {
        z-index: 4;
    }

    // dividers
    .divider {
        position: absolute;
        z-index: 2;
        background-color: var(--chart-section-divider);

        &.vertical {
            width: 3px;
            height: 100%;
            top: 0;
            left: 50%;
            margin-left: -1.5px;
            z-index: 4;
        }

        &.horizontal {
            width: calc(100% + 300px);
            height: 3px;
            left: -150px;
            top: 50%;
            margin-top: -1.5px;
            z-index: 4;
        }

        &.pie-slice {
            width: 2px;
            height: 100%;
            top: 0;
            left: 50%;
            margin-left: -1px;
            background-color: var(--chart-divider);
            opacity: 0.1;

            &.text-block-container {
                width: 4px;
                margin-left: -2px;
                background-color: transparent;
                z-index: 3;

                .block {
                    position: absolute;
                    width: 100%;
                    height: 8%;
                    bottom: 82%;
                    background-color: white;
                }
            }
        }

        &.peer {
            background-color: transparent;
            z-index: 5;
            opacity: 1;
        }

        .peer-divider {
            position: absolute;
            transition: height 0.5s, bottom 0.5s;
            background-color: grey;
        }
    }

    // circles
    .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        z-index: 5;
        border: 1px solid var(--chart-divider);
        opacity: 0.1;

        // target circle
        &.target {
            border-color: var(--chart-circle-target);
            border-width: 2px;
            opacity: 1;
        }

        // centre circle
        &.centre {
            width: 4%;
            height: 4%;
            margin-top: -2%;
            margin-left: -2%;
            background-color: white;
            z-index: 6;
            opacity: 1;
        }

        // sections
        &.sections {
            width: 63%;
            height: 63%;
            margin-top: -31.5%;
            margin-left: -31.5%;
            border-width: 7px;
            z-index: 3;

            .point {
                position: absolute;
                width: 25px;
                height: 25px;
                border-radius: 50%;

                &.point-1 {
                    top: -15px;
                    left: 50%;
                    margin-left: 15px;
                }

                &.point-2 {
                    right: -15px;
                    bottom: 50%;
                    margin-bottom: 15px;
                }

                &.point-3 {
                    right: -15px;
                    top: 50%;
                    margin-top: 15px;
                }

                &.point-4 {
                    bottom: -15px;
                    left: 50%;
                    margin-left: 15px;
                }

                &.point-5 {
                    bottom: -15px;
                    right: 50%;
                    margin-right: 15px;
                }

                &.point-6 {
                    left: -15px;
                    top: 50%;
                    margin-top: 15px;
                }

                &.point-7 {
                    left: -15px;
                    bottom: 50%;
                    margin-bottom: 15px;
                }

                &.point-8 {
                    top: -15px;
                    right: 50%;
                    margin-right: 15px;
                }
            }

            .blocker {
                position: absolute;
                background-color: white;
                
                &.one, &.three {
                    width: 45px;
                    height: 9px;
                    left: 50%;
                    margin-left: -22.5px;
                }

                &.two, &.four {
                    height: 45px;
                    width: 9px;
                    top: 50%;
                    margin-top: -22.5px;
                }

                &.one {
                    top: 0;
                    margin-top: -8px;
                }

                &.two {
                    right: 0;
                    margin-right: -8px;
                }

                &.three {
                    bottom: 0;
                    margin-bottom: -8px;
                }

                &.four {
                    left: 0;
                    margin-left: -8px;
                }
            }

            .title-wrapper {
                position: absolute;
                width: 100%;
                text-align: center;

                .title, .sub-title {
                    line-height: 1.25;
                }

                .title {
                    font-size: 200%;
                }

                .sub-title {
                    font-size: 180%;
                }

                &.one, &.four {
                    top: 5%;
                }

                &.one, &.two {
                    left: 41%;
                }

                &.one, &.three {
                    transform: rotate(45deg);
                }

                &.two, &.three {
                    bottom: 5%;
                }

                &.three, &.four {
                    right: 41%;
                }

                &.two, &.four {
                    transform: rotate(-45deg);
                }
            }
        }

        .percentage {
            text-align: center;
            transform: rotate(-4deg);
        }
    }

    // slices
    .slice {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        margin: 24%;
        // value in fourth param is calculated based on now many behaviours (N) there are
        // if amount is between 3 and 8, then calculation is 50% * (1 - tan(90deg - 360deg / N))
        // if amount is over 8, then calculation is 50% * (1 + tan(360deg / N))
        // found from accepted answer https://stackoverflow.com/questions/56797060/css-only-pie-chart-how-to-add-spacing-padding-between-slices
        clip-path: polygon(calc(50%) 50%, calc(50%) 0%, 100% 0%, 100% calc(-70.7107%), 50% calc(50%));
        transform: rotate(0deg);
        transition: margin 0.5s;
        z-index: 1;
        background-color: var(--chart-slice);

        &.peer {
            border: 3px solid grey;
            background-color: transparent;
            z-index: 5;
        }

        &.percentage-button {
            background-color: transparent;
            z-index: 10;
        }
    }

    // text
    .behaviour-name-wrapper {
        position: absolute;
        width: 85%;
        height: 85%;
        top: 7.5%;
        left: 7.5%;

        .text {
            position: absolute;
            top: 0;
            left: 50%;
            margin-top: 20px;
            width: 17.5%;
            padding: 0 10px;
            text-align: center;
            transform: rotate(13deg);
            font-size: 130%;
            color: var(--chart-typography-behaviour);
        }
    }

    .topic-click {
        position: absolute;
        width: 50%;
        height: 50%;
        z-index: 10;
        background: none;
        opacity: 0;
        cursor: pointer;

        &.top {
            top: 0;
            &.left {
                border-radius: 100% 0 0 0;
            }
            &.right {
                border-radius: 0 100% 0 0;
            }
        }
        &.bottom {
            bottom: 0;
            &.left {
                border-radius: 0 0 0 100%;
            }
            &.right {
                border-radius: 0 0 100% 0;
            }
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }
}

// dropdown in admin to select assessment
.assessment-chart-dropdown {
    z-index: 10;
    display: flex;
    
}

.chart-key-wrapper {
    width: 1000px;
    margin-top: 50px;
}

.chart-key {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .bar {
        width: 200px;
        height: 5px;
        background-color: #c6c6c6;
        margin-right: 30px;
    }

    p {
        width: 150px;
        font-size: 0.75rem;
    }
}